import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import navigate from '../utils/navigate';

import SEO from '../components/seo';

const IndexPage = () => {
  const { locale } = useIntl();

  useEffect(() => {
    navigate(locale, '/');
  }, [locale]);

  return <SEO />;
};

export default IndexPage;
